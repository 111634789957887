import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['expand', 'toggleContainer', 'select', 'jetSelect', 'viewCategories', 'categoriesTurbo', 'submit'];
    static values = {
        url: {
            type: String,
            default: '',
        },
    };

    initialize() {
        this.handleEvent = function (event) {
            if (event.type === 'searchselect:updated') {
                this.updateSelectHighlight(event.currentTarget, false, event);
            }
        };
    }

    categoriesLoaded() {
        if (this.hasSubmitTarget) {
            this.submitTarget.removeAttribute('disabled');
            this.submitTarget.classList.remove('form-disabled');
        }

        this.selectTargets.forEach((ele) => {
            this.updateSelectHighlight(ele, true);
        });

        this.selectTargets.forEach((ele) => {
            ele.addEventListener('searchselect:updated', this);
        });
    }

    jetSelectionChanged() {
        this.categoriesTurboTarget.src = `${this.urlValue}?selected_jem=${this.jetSelectTarget.dataset.searchSelectValue}&view=${this.viewCategoriesTarget.name}`;
    }

    updateSelectHighlight(ele, isConnect = false, event) {
        if (!ele.dataset.searchSelectValue) {
            ele.classList.remove('border-gray-700');
            ele.classList.add('border-bkblue', 'border-2');
            ele.classList.add('bg-opaque-blue');
            if (ele.closest('[data-subcat]') && (isConnect || event?.detail?.prev)) {
                this.updateExpandHighlight(ele, 1);
            }
        } else if (!isConnect) {
            ele.classList.remove('bg-opaque-blue');

            if (ele.closest('[data-subcat]') && !event?.detail?.prev) {
                this.updateExpandHighlight(ele, -1);
            }
        }
    }

    updateExpandHighlight(ele, count) {
        let category = ele.closest('[data-subcat]').dataset.subcat;
        let expandEle = document.querySelector(`[data-cat="${category}"]`);

        count = expandEle.dataset.unselected = expandEle.dataset.unselected ? +expandEle.dataset.unselected + count : 1;
        if (count > 0) {
            expandEle.classList.remove('hover:bg-gray-100', 'focus:bg-gray-100');
            expandEle.classList.add('bg-opaque-blue', 'hover:opacity-80');
        } else {
            expandEle.classList.add('hover:bg-gray-100', 'focus:bg-gray-100');
            expandEle.classList.remove('bg-opaque-blue', 'hover:opacity-80');
        }
    }

    validateList(e) {
        let requiredFields = this.element.querySelectorAll('[data-selection-required=true]');
        let unmapped = Array.from(requiredFields).find((x) => {
            return x.classList.value.includes('bg-opaque-blue');
        });
        if (unmapped) {
            let res = confirm(
                'Are you sure you want to save?  Your entries will not post to accounting until all required fields are mapped.'
            );
            if (!res) {
                e.preventDefault();
            }
        }
    }

    toggleSubcat(e) {
        e.preventDefault();

        let category = e.currentTarget.getAttribute('data-cat');
        let chevron = this.toggleContainerTarget.querySelector(`[data-cat="${category}"]`);
        if (chevron.querySelector('svg').classList.contains('rotate-chevron')) {
            chevron.querySelector('svg').classList.remove('rotate-chevron');
        } else {
            chevron.querySelector('svg').classList.add('rotate-chevron');
        }
    }

    subcategoriesLoaded(e) {
        const { target } = e;
        const subcategories = target.querySelectorAll('[data-controller="search-select"]');
        subcategories.forEach((ele) => {
            this.updateSelectHighlight(ele, true);
            ele.addEventListener('searchselect:updated', this);
        });
    }
}
