import search_controller from './search_controller';
import { enter, leave } from 'el-transition';
import { useClickOutside } from 'stimulus-use';

export default class extends search_controller {
    static targets = ['search', 'select', 'menu', 'button', 'option', 'optionContainer', 'valueMask'];

    formSubmit(e) {
        if (this.data.get('required') && this.selectTarget.value == '') {
            e.detail.formSubmission.stop();
            return false;
        }
    }

    initialize() {
        this.element.closest('form') &&
            this.element.closest('form').addEventListener('turbo:submit-start', this.formSubmit.bind(this));
        useClickOutside(this);
        this.selectChanged();
    }

    clickOutside() {
        if (!this.menuTarget.classList.contains('hidden')) {
            this.closeMenu();
        }
    }

    connect() {}

    toggleMenu() {
        if (this.menuTarget.classList.contains('hidden')) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    }

    getScrollParent(element) {
        if (element == document.body) {
            return document.body;
        }
        if (element.classList.contains('dd-scroll-container')) {
            return element;
        } else {
            return this.getScrollParent(element.parentNode);
        }
    }

    closeMenu() {
        leave(this.menuTarget);
        this.menuTarget.style.bottom = null;
        if (this.hasSearchTarget) {
            this.searchTarget.value = '';
            super.userInput();
        }
    }

    openMenu() {
        var x;
        x = this.getScrollParent(this.element);
        enter(this.menuTarget).then(() => {
            if (selected.length) {
                that.highlightOption(selected[selected.length - 1]);
            } else {
                that.highlightFirstOption();
            }
        });
        if (
            this.element.getBoundingClientRect().top - x.getBoundingClientRect().top > this.menuTarget.offsetHeight &&
            x.getBoundingClientRect().bottom - this.element.getBoundingClientRect().bottom <
                this.menuTarget.offsetHeight
        ) {
            this.menuTarget.style.bottom = this.element.clientHeight + 'px';
        }
        if (this.hasSearchTarget) {
            this.searchTarget.focus();
        }
        var selected = this.optionTargets.filter((x) => x.classList.contains('selected'));
        let that = this;
    }

    arrowHandle(e) {
        if (e.which == 38 || e.which == 40 || e.which == 13 || e.which == 27) {
            let optionTargets = this.optionTargets.filter((x) => !x.classList.contains('hidden'));

            let selIdx = optionTargets.findIndex((x) => x.classList.contains('highlight'));
            let selected = optionTargets[selIdx];
            if (selected) {
                if (e.which == 13) {
                    // Enter key
                    this.searchFromSelect(optionTargets[selIdx]);
                    this.buttonTarget.focus();
                } else if (e.which == 38 && selIdx) {
                    // Up key
                    if (selIdx > 0) {
                        this.highlightOption(optionTargets[selIdx - 1]);
                    }
                } else if (e.which == 40) {
                    // Down key
                    if (selIdx < optionTargets.length - 1) {
                        this.highlightOption(optionTargets[selIdx + 1]);
                    }
                }
            }
        } else if (e.which == 27 || e.which == 9) {
            this.closeMenu();
            this.buttonTarget.focus();
        }
    }

    userInput() {
        super.userInput();
        this.highlightFirstOption();
    }

    highlightFirstOption() {
        var first = this.optionTargets.find((x) => !x.classList.contains('hidden'));
        this.highlightOption(first);
    }

    hoverHighlight(e) {
        this.highlightOption(e.target);
    }

    highlightOption(opt) {
        this.element.querySelectorAll('[data-select-value].highlight').forEach((x) => x.classList.remove('highlight'));
        if (opt) {
            let scrollOffset = opt.offsetTop - opt.parentElement.offsetTop;
            if (
                scrollOffset + opt.getBoundingClientRect().height >
                opt.parentElement.scrollTop + opt.parentElement.getBoundingClientRect().height
            ) {
                opt.parentElement.scrollTo({
                    top:
                        scrollOffset +
                        opt.getBoundingClientRect().height -
                        opt.parentElement.getBoundingClientRect().height,
                });
            }
            if (scrollOffset < opt.parentElement.scrollTop) {
                opt.parentElement.scrollTo({
                    top: scrollOffset,
                });
            }
            opt.classList.add('highlight');
        }
    }

    clickOption(e) {
        this.searchFromSelect(e.currentTarget);
    }

    searchFromSelect(option) {
        let isMultiple = this.data.get('type') === 'multiple';
        let updatedArray = [];
        let val = option.getAttribute('data-select-value');
        let prev = this.data.get('value');

        if (isMultiple) {
            if (option.classList.contains('selected')) {
                option.classList.remove('selected');
                updatedArray = this.getUpdatedArray(val, prev, false);
            } else {
                option.classList.add('selected');
                updatedArray = this.getUpdatedArray(val, prev);
            }

            for (let i = 0; i < this.selectTarget.options.length; i++) {
                this.selectTarget.options[i].selected = updatedArray.includes(this.selectTarget.options[i].value);
            }
        } else {
            this.element.querySelectorAll('[data-select-value].selected').forEach((x) => {
                prev = x.dataset.selectValue;
                x.classList.remove('selected');
            });
            option.classList.add('selected');
            this.selectTarget.value = val;
        }

        let searchEl = this.element.closest('[data-controller*="change-tracker"]');
        val = isMultiple ? updatedArray.join() : val;
        this.data.set('value', val);

        if (searchEl && val != prev) {
            let changeSelectController = this.application.getControllerForElementAndIdentifier(
                searchEl,
                'change-tracker'
            );
            changeSelectController.markDirty();
        }
        if (isMultiple) {
            this.valueMaskTarget.innerText = this.getUpdatedValue(updatedArray);
        } else {
            this.valueMaskTarget.innerText = this.selectTarget.selectedOptions[0].label;
            this.closeMenu();
        }

        this.sendEvent(this.element, 'searchselect:updated', {
            detail: {
                value: val,
                prev: prev,
            },
        });
        let form = this.element.closest('form');
        if (form) this.sendEvent(form, 'form:check-required-fields');
    }

    getUpdatedArray(current, prev, add = true) {
        let newArray = prev ? prev.split(',') : [];
        if (add) {
            newArray.push(current);
        } else {
            let currentIndex = newArray.indexOf(current);
            currentIndex !== -1 && newArray.splice(currentIndex, 1);
        }
        return newArray;
    }

    getUpdatedValue(selectedArray) {
        let updatedValue = '';
        if (selectedArray.length > 0) {
            selectedArray.forEach((opt) => {
                updatedValue && (updatedValue += ', ');
                updatedValue += this.element.querySelector('[data-select-value="' + opt + '"]').innerText;
            });
        } else {
            updatedValue = this.data.get('placeholder');
        }
        return updatedValue;
    }

    selectChanged() {
        let isMultiple = this.data.get('type') === 'multiple';
        let selectedOptions = this.selectTarget.selectedOptions;
        let selectionText = '';

        if (!isMultiple) {
            this.element
                .querySelectorAll('[data-select-value].selected')
                .forEach((x) => x.classList.remove('selected'));
        }

        for (let i = 0; i < selectedOptions.length; i++) {
            const option = selectedOptions[i];

            const ele = this.element.querySelector(`[data-select-value="${option.value}"]`);

            if (ele) {
                ele.classList.add('selected');
                selectionText && (selectionText += ', ');
                selectionText += option.label;
            }
        }

        this.valueMaskTarget.innerText = selectionText || this.data.get('placeholder');
    }

    dropdownButtonKeyUp(e) {
        if ((e.which >= 65 && e.which <= 90) || (e.which >= 48 && e.which <= 57)) {
            this.openMenu();
            this.searchTarget.value += e.key;
            this.userInput(e);
        }
    }

    addOption(value, display, selected = false) {
        var selectOpt = document.createElement('div');
        selectOpt.setAttribute('data-search-value', display.toLowerCase().replace(/ /g, ''));
        selectOpt.setAttribute('data-action', 'click->search-select#clickOption');
        selectOpt.setAttribute('data-select-value', value);
        selectOpt.setAttribute('class', 'p-2 flex justify-between text-black hover:bg-gray-50 cursor-pointer option');
        selectOpt.innerText = display;

        this.optionContainerTarget.appendChild(selectOpt);

        var opt = document.createElement('option');
        opt.value = value;
        opt.innerText = display;
        this.selectTarget.add(opt);
        if (selected) {
            this.selectTarget.value = value;
            this.selectChanged();
            this.data.set('value', value);
            this.sendEvent(this.element, 'searchselect:updated');
        }
    }
}
