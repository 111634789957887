import ApplicationController from './application_controller';
export default class extends ApplicationController {
    static targets = ['statusCheckboxes', 'typeCheckboxes', 'form'];

    handleTypeChange(e) {
        this.typeCheckboxesTargets.forEach((element) => {
            if (e.target.value !== 'all' && element.value === 'all') {
                element.checked = false;
            }
        });
        this.formTarget.submit();
    }

    handleStatusChange(e) {
        this.statusCheckboxesTargets.forEach((element) => {
            if (e.target.value !== 'all' && element.value === 'all') {
                element.checked = false;
            }
        });
        this.formTarget.submit();
    }

    removeFilterOption(e) {
        const filterkey = e.currentTarget.getAttribute('data-notification-filter-key');
        const filtervalue = e.currentTarget.getAttribute('data-notification-filter-value');
        switch (filterkey) {
            case 'status':
                this.statusCheckboxesTargets.forEach((element) => {
                    this.toggleCheckbox(element, filtervalue);
                });
                break;
            case 'type':
                this.typeCheckboxesTargets.forEach((element) => {
                    this.toggleCheckbox(element, filtervalue);
                });
        }
        e.currentTarget.remove();
        this.formTarget.submit();
    }

    toggleCheckbox(element, value) {
        if (element.value === value) {
            element.checked = !element.checked;
        }
        if (value !== 'all' && element.value === 'all') {
            element.checked = false;
        }
    }
}
