import ApplicationController from './application_controller';
import { useClickOutside, useDebounce } from 'stimulus-use';
import { enter, leave } from 'el-transition';
import { stringify } from 'querystring';

export default class extends ApplicationController {
    static targets = [
        'input',
        'optionsContainer',
        'hiddenInput',
        'option',
        'menu',
        'valueMask',
        'startTyping',
        'defaultSelection',
    ];

    static values = {
        offset: {
            type: Number,
            default: 0,
        },
        url: {
            type: String,
            default: '',
        },
        totalRecords: {
            type: Number,
            default: 1,
        },
    };

    static debounces = ['input'];

    initialize() {
        useClickOutside(this, { onlyVisible: false });
    }

    connect() {
        useDebounce(this, { wait: 500 });
    }

    input() {
        this.offsetValue = 0;
        this.totalRecordsValue = 1;
        this.optionsContainerTarget.innerHTML = '';
        this.getData();
        this.highlightFirstOption();
    }

    getData() {
        var context = this;
        if (this.inputTarget.value.trim().length == 0) {
            this.startTypingTarget.classList.remove('hidden');
        } else if (this.totalRecordsValue > this.offsetValue) {
            this.startTypingTarget.classList.add('hidden');
            this.ajax({
                url: context.urlValue,
                type: 'POST',
                dataType: 'json',
                data: stringify({
                    query: context.inputTarget.value,
                    limit: 20,
                    offset: context.offsetValue,
                }),
                success: function (data) {
                    context.totalRecordsValue = data.total_count;
                    context.appendElements(data.results);
                },
            });
        }
    }

    appendElements(data) {
        let element;
        if (this.element.dataset.emptyOptionPlaceholder && !this.hasDefaultSelectionTarget) {
            element =
                '<div class="option p-2 flex justify-between hover:bg-gray-50 cursor-pointer" data-autocomplete-target="defaultSelection" data-autocomplete-target="option" data-action="click->autocomplete#setValue" data-value="">' +
                this.element.dataset.emptyOptionPlaceholder +
                '</div>';
            this.optionsContainerTarget.innerHTML += element;
        }
        for (let i in data) {
            let element =
                '<div class="option p-2 flex justify-between hover:bg-gray-50 cursor-pointer" data-autocomplete-target="option" data-action="click->autocomplete#setValue" data-value=' +
                data[i].value +
                '>' +
                data[i].label +
                '</div>';
            this.optionsContainerTarget.innerHTML += element;
        }
        this.scrollParent();
    }

    handleScroll() {
        if (
            this.optionsContainerTarget.offsetHeight + this.optionsContainerTarget.scrollTop >=
            this.optionsContainerTarget.scrollHeight
        ) {
            this.offsetValue = this.offsetValue + 20;
            this.getData();
        }
    }

    clickOutside() {
        this.closeMenu();
    }

    toggleMenu() {
        if (this.menuTarget.classList.contains('hidden')) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
        this.scrollParent();
    }

    scrollParent() {
        let x;
        x = this.getScrollParent(this.element);
        if (
            x.getBoundingClientRect().bottom - this.element.getBoundingClientRect().bottom <
            this.menuTarget.offsetHeight
        ) {
            x.scrollTo({
                top: this.menuTarget.offsetHeight + x.scrollTop,
            });
        }
    }

    getScrollParent(element) {
        if (element == document.body) {
            return document.body;
        }
        if (element.classList.contains('dd-scroll-container')) {
            return element;
        } else {
            return this.getScrollParent(element.parentNode);
        }
    }

    closeMenu() {
        leave(this.menuTarget);
        this.inputTarget.value = '';
        this.optionsContainerTarget.innerHTML = '';
        this.startTypingTarget.classList.remove('hidden');
    }

    openMenu() {
        enter(this.menuTarget).then(() => {
            this.highlightFirstOption();
        });
        this.inputTarget.focus();
    }

    highlightFirstOption() {
        var first = this.optionTargets[0];
        first && this.highlightOption(first);
    }

    highlightOption(opt) {
        this.optionTargets.forEach((x) => x.classList.remove('highlight'));
        if (opt) {
            let scrollOffset = opt.offsetTop;
            if (
                scrollOffset + opt.getBoundingClientRect().height >
                opt.parentElement.scrollTop + opt.parentElement.getBoundingClientRect().height
            ) {
                opt.parentElement.scrollTo({
                    top:
                        scrollOffset +
                        opt.getBoundingClientRect().height -
                        opt.parentElement.getBoundingClientRect().height,
                });
            }
            if (scrollOffset < opt.parentElement.scrollTop) {
                opt.parentElement.scrollTo({
                    top: scrollOffset,
                });
            }
            opt.classList.add('highlight');
        }
    }

    arrowHandle(e) {
        if (e.which == 38 || e.which == 40 || e.which == 13) {
            let optionTargets = this.optionTargets;

            let selIdx = optionTargets.findIndex((x) => x.classList.contains('highlight'));
            let selected = optionTargets[selIdx];
            if (selected) {
                if (e.which == 13) {
                    // Enter key
                    optionTargets[selIdx].click();
                    e.preventDefault();
                } else if (e.which == 38 && selIdx) {
                    // Up key
                    if (selIdx > 0) {
                        this.highlightOption(optionTargets[selIdx - 1]);
                    }
                } else if (e.which == 40) {
                    // Down key
                    if (selIdx < optionTargets.length - 1) {
                        this.highlightOption(optionTargets[selIdx + 1]);
                    }
                }
            }
        } else {
            return true;
        }
    }

    setValue(e) {
        this.hiddenInputTarget.value = e.target.dataset.value;
        this.element.dataset.searchSelectValue = e.target.dataset.value;
        this.sendEvent(this.element, 'searchselect:updated');
        this.sendEvent(this.element, 'change-tracker:markDirty');
        this.valueMaskTarget.innerHTML = e.target.innerHTML;
        this.closeMenu();
    }
}
